import { createGlobalStyle } from 'styled-components'
import { fluidRange } from 'polished'
import { pxToRem } from 'helpers'

export const COLOR_CONSTANTS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  SALUTE: '#272831',
  COSMIC_ENERGY: '#9193A9',
  SEMI_BLACK: '#1A1A1A',
  FLUORESCENT_RED: '#E83C51',
  ZHEN_ZHU_BAI_PEARL: '#FAFAFD',
  FLAT_BLUE: '#3077B0',
  SOLITUDE: '#E9EAF1',
  GLACIER_PEARL: '#D6D7E1',
  BOYZONE: '#2D9CDB',
  RED: '#FF0000',
  ROCKMAN_BLUE: '#4AA1EB',
  AMERICANA: '#0077B5',
  JUNGLE_GREEN: '#27AE60',
  ALGAE: '#55AA68',
  VIOLET_WHIMSEY: '#D6D7E0',
  CARPACCIO: '#E34234',
  BIO_BLUE: '#3B5998',
  BRIGHT_NAVY_BLUE: '#417EE0',
  BOYSENBERRY_SHADOW: '#F4F4F9',
  WAYWARD_WIND: '#DEDFE2',
  PUMPKIN_PIE: '#E69C59',
  DISTANT_HORIZON: '#F1F8FC',
  VENUS_MIST: '#60616E',
  COMMON_FELDSPAR: '#868E96',
  ANTI_FLASH_WHITE: '#F1F3F5',
  BEE_HALL: '#ECC963',
  PUNCH: '#DC4333',
  AKEBI_PURPLE: '#8A3AB9',
  ORANGE_RED: '#FF4500',
  RED_TOLUMNIA_ORCHID: '#BD081C',
  CYANITE: '#1AB7EA',
  KEN_MASTERS_RED: '#EC2C25',
  FACEBOOK: '#4267B2',
  YELLOW_STAR: '#EBCA62',
  RED_STAR: '#FA5252',
  DENIM: '#18191F',
  SPRUCE: '#203247',
  NAVY: '#002E69',
  COBALT: '#0063E3',
  SKY: '#2482FD',
  SAPPHIRE: '#6D7C8F',
  AEGEAN: '#4A6B96',
  CERULEAN: '#6682A5',
  ALICE: '#C4D7EE',
  CHIFFON: '#E5E5E5',
  SALT: '#EAF3FF',
  LACE: '#D3E6FF',
  DAISY: '#F5F8FD',
  BONE: '#C4C4C4',
  COTTON: '#E0E3E8',
  CARROT: '#FFF9C8',
  DARKGRAY: '#3d3d3d',
  PURPLE: '#a538f5',
}

export const fontStack = 'Poppins, sans-serif'

export const colors = {
  primary: COLOR_CONSTANTS.COBALT,
  primaryText: COLOR_CONSTANTS.NAVY,
  secondaryText: COLOR_CONSTANTS.SAPPHIRE,
  ternaryText: COLOR_CONSTANTS.VENUS_MIST,
  white: COLOR_CONSTANTS.WHITE,
  black: COLOR_CONSTANTS.BLACK,
  error: COLOR_CONSTANTS.FLUORESCENT_RED,
  warning: COLOR_CONSTANTS.PUMPKIN_PIE,
  success: COLOR_CONSTANTS.JUNGLE_GREEN,
}

export const radius = {
  xs: '1px',
  s: '2px',
  sm: '3px',
  m: '4px',
  l: '6px',
  xl: '8px',
  xxl: '16px',
  xxxl: '24px',
  circle: '50%',
  pill: '9999px',
}

export const space = {
  xxs: pxToRem(2),
  xs: pxToRem(4),
  s: pxToRem(8),
  sm: pxToRem(12),
  m: pxToRem(16),
  xm: pxToRem(24),
  l: pxToRem(32),
  xl: pxToRem(64),
  xxl: pxToRem(128),
  xxxl: pxToRem(256),
}

export const fontSizes = {
  xxxs: pxToRem(8),
  xxs: pxToRem(10),
  xs: pxToRem(12),
  s: pxToRem(14),
  m: pxToRem(16),
  l: pxToRem(18),
  xl: pxToRem(20),
  xxl: pxToRem(22),
  xxxl: pxToRem(32),
  xxxxl: pxToRem(36),
  '4xl': pxToRem(42),
  '5xl': pxToRem(46),
  '6xl': pxToRem(52),
}

export const fontWeights = {
  light: 200,
  semiLight: 300,
  normal: 400,
  medium: 500,
  bold: 600,
  extraBold: 700,
}

export const fontStyles = {
  normal: 'normal',
  italic: 'italic',
  oblique: 'oblique',
}

export const lineHeights = {
  xs: 1,
  s: 1.2,
  m: 1.3,
  l: 1.4,
  xl: 1.5,
}

export const getTransition = (property) => `${property} 0.5s cubic-bezier(0.19, 1, 0.22, 1)`

export const GlobalStyles = createGlobalStyle`
  html, body, #__next {
    height:100%;
  }
  html {
    ${fluidRange({
      prop: 'font-size',
      fromSize: '14px',
      toSize: '18px',
    })}
  }
  body {
    font-family: ${fontStack};
    font-weight: 400;
    color: ${colors.primaryText};
    background-color: ${COLOR_CONSTANTS.DAISY};
    min-width: 320px;
    overflow-x: hidden;
  }
  body,
  button,
  input,
  textarea,
  select {
    font-family: ${fontStack};
  }
  button {
    font-family: inherit;
    font-size: ${fontSizes.m};
    background-color: transparent;
  }
  button {
    border: none;
    margin: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
  }
  input, input[type=text], textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .react-datepicker {
    border: 1px solid ${COLOR_CONSTANTS.SOLITUDE} !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: ${COLOR_CONSTANTS.WHITE} !important;
    border-bottom: 1px solid ${COLOR_CONSTANTS.SOLITUDE} !important;
  }
  .react-datepicker__day--selected.react-datepicker__day--today {  
    color: ${COLOR_CONSTANTS.WHITE} !important;
  }
  .react-datepicker__day--selected {
    background-color: ${colors.primary} !important;
  }
  .react-datepicker__input-container > input {
    width: inherit;
    padding-top: ${space.s};
    padding-bottom: ${space.s};
    padding-right: ${space.m};
    padding-left: ${pxToRem(48)};
    border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
    border-radius: ${radius.l};
    height: ${pxToRem(40)};
    color: ${colors.primaryText};
    font-size: ${fontSizes.xs};
    outline: 0;
    ::placeholder {
      color: ${COLOR_CONSTANTS.GLACIER_PEARL};
      font-weight: ${fontWeights.normal};
    }
  }
  .MuiRating-icon {
    color: ${COLOR_CONSTANTS.BEE_HALL};
  }
  .MuiSlider-root {
    color:${colors.primaryText} !important
  }
  .MuiSlider-thumb span {
    font-size: ${fontSizes.xs} !important; 
  }
  .slick-prev, .slick-next {
    top: 45%;
    width: 16px;
    height: 16px;
  }
  .quill {
    width: 100%;
  }
  .ql-editor {
    min-height: 200px;
    font-family: ${fontStack};
    color: ${COLOR_CONSTANTS.SALUTE};
  }
  .ql-editor.ql-blank::before {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-family: ${fontStack};
    font-style: normal;
    font-size: ${fontSizes.s};
  }
  .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button{
    height: 20px;
    width: 20px;
  }
  .ql-container, .ql-editor {
    height: calc(100% - 45px) !important;
  }
  .ql-toolbar.ql-snow {
    border-radius: ${radius.xl} ${radius.xl} 0 0;
  }
  .ql-container.ql-snow {
     border-radius: 0 0 ${radius.xl} ${radius.xl};
  }
  .ql-icon-picker {
    margin-top: -${space.xxs};
  }
  .page-break-after {
    page-break-after: always;
  }
  .slick-signup {
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translate(0, -50%);
    list-style-type: none; 
  }
  .slick-signup li {
    cursor: pointer;
  }
  .red-border {
    border-color: ${colors.primary} !important;
  }
  em-emoji-picker {
    max-height: 350px;
  }
  .fc .fc-toolbar, .fc .fc-popover {
    display: none !important;
  }
  .fc-col-header-cell-cushion {
    color: ${colors.secondaryText};
  }
  .fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-scrollgrid {
    border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  }
  .fc-direction-ltr {
    height: 100%;
    background-color: ${COLOR_CONSTANTS.WHITE};
    border-radius: ${radius.l};
  }
  .fc-day-today, .fc-day-future {
    background-color: ${COLOR_CONSTANTS.WHITE} !important;
  }
  .fc-day-past, .fc-event-past {
    background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  }
  .fc-day-today, .fc-day-future {
    cursor: pointer;
  }
  .fc a[data-navlink] {
    pointer-events: none; 
  }
  .fc .fc-daygrid-body, .fc .fc-scrollgrid table {
    width: 100% !important;
  }
  .fc .fc-daygrid-day-top {
   flex-direction: row; 
  }
  .fc .fc-daygrid-day:hover .calendar-create-icons {
    display: flex; 
  }
  .fc .fc-daygrid-day:hover .fc-daygrid-day-top {
    opacity: 1; 
  }
  .fc .fc-daygrid-day-number, .fc .fc-daygrid-body {
    z-index: 0;
  }
  .fc .fc-daygrid-day-number {
    color: ${colors.secondaryText};
    font-size: ${fontSizes.xs};
    padding: 0 !important;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: initial;  
  }
  .fc-day-today .fc-daygrid-day-number {
     color: ${colors.white};
     background-color: ${colors.primary};
     border: 1px solid ${colors.primary};
     border-radius: ${radius.pill};
     min-width: 22px;
     text-align: center;
  }
  .fc-col-header-cell-cushion, .fc-list-day-text, .fc-list-day-side-text, .fc-list-event-time, .fc-theme-standard .fc-popover-header {
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes.s};
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-left: 0;
  }
  .fc .fc-scroller-harness  {
   /* overflow: unset !important;*/
  }
  .fc .fc-scroller-liquid-absolute {
  /*  overflow: unset !important; */
  }
  .fc .fc-daygrid-day-events{
    height: calc(100% - 22px);
  }
  .fc .fc-daygrid-more-link {
    font-size: ${fontSizes.xs};
  }
  .fc .fc-daygrid-day-bottom{
    /* position: absolute;
     bottom: -3px;*/
  }
  .fc .fc-daygrid-event-harness {
    left: 0 !important;
    right: 0 !important;
    margin-bottom: ${space.xs};  
  }
  .fc-h-event .fc-event-main {
    color: ${colors.primaryText};
  }
  .fc-h-event {
    border: none !important;
    background: rgba(0, 0, 0, 0) !important;
  }
  .fc-list-event-graphic {
    display: none; 
  }
  .fc-list-event-title {
    vertical-align: middle;
  }
  .fc .fc-list-sticky .fc-list-day > * {
    z-index: 1;
  }
  .fc-list-day-cushion .fc-cell-shaded, .fc-theme-standard .fc-list-day-cushion {
    background-color: ${COLOR_CONSTANTS.SOLITUDE};
  }
  .fc-list-event-time {
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes.xs};
  }
  .fc .fc-more-popover {
    max-width: 400px;
    overflow-y: auto;
    height: 100%;
  }
  .fc .fc-daygrid-event {
    z-index: 1;
    margin-top: 0;
  }
  .fc-event:hover,  .fc .fc-daygrid-event-harness:hover  {
    z-index: 10;
    cursor: pointer; 
  }
  .fc-list-table  .fc-day-today {
    color: ${colors.primary}; 
  }
  .fc table {
    width: 100%;
  }
  .fc .fc-scrollgrid-liquid {
    border-radius: ${radius.l};
  }
  .fc .fc-daygrid-bg-harness {
    display: none;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(253, 83, 89, 0.7);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 16px rgba(253, 83, 89, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(253, 83, 89, 0);
    }
   }
   .MuiPaper-elevation0 {
      height: 100%;
   }
   .MuiPaper-elevation1 {
      box-shadow: none !important;
   }
   .social-profiles-slider .slick-track {
      margin-left:0;
      margin-right:0;
   }
   .s-alert-success {
     background: ${colors.primary};
   }
   .s-alert-error {
     background: ${colors.error};
   }
   .react-datepicker-popper-right-position {
     right: 0;
     left: unset !important;
   }
   .tui-image-editor-header { 
      display: none; 
   } 
   .tui-image-editor-container .tui-image-editor-main { 
      top: 0; 
    }
   .tui-image-editor-container .tui-image-editor-help-menu > .tui-image-editor-item 
   {
      margin-top: -${space.xs} !important;
   }
   .tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.apply label,
   .tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.cancel label {
      vertical-align: 0px !important;
   }
   .tui-image-editor-menu-crop .tie-crop-preset-button {
      display: none !important;
   }
  .tui-image-editor-menu-crop .tui-image-editor-submenu {
      height: 30px !important;  
  }
  #styled-mentions-input {
      padding: ${space.m} ${space.m} 0;
      width: 100%;
      font-size: ${fontSizes.s};
      resize: none;
      outline: none;
      border-width: 0;
      ::placeholder {
        color: ${COLOR_CONSTANTS.SAPPHIRE};
        font-size: ${fontSizes.s};
      }
      border: none !important;
  }
  #styled-mentions-input-comment {
      font-size: ${fontSizes.xs} !important;
      outline: none;
      border: none !important;
      padding: 0;
  }
  .inbox-mention-textarea-note {  
      background-color: ${COLOR_CONSTANTS.CARROT} !important;
  }
  .inbox-mention {
      position: relative;
      z-index: 1;
      background-color: #fbc916;
      color: ${COLOR_CONSTANTS.BLACK};
      font-weight: ${fontWeights.medium} !important;
      border-radius: ${radius.m}; 
  }
  .report-faded .report-faded-wrapper {
    display: flex;
  }
  .report-faded .report-content, .report-faded .report-charts {
    display: none;
  }
  .report-faded.report-section {
    margin-bottom: ${space.m};
  }
  .hide-report-up-down-indicator .report-up-down-indicator {
    display: none;
  }
  .hide-hidden-sections .report-faded {
    display: none;
  }
  .show-extraText-wrapper, .hide-extraText-wrapper > div {
    cursor: pointer;
  }
  .report-content-extraText-wrapper .show-extraText-wrapper{
    display: none;
  }
  .hide-extraText-wrapper {
    display: none !important;
  }
  .report-content-extraText-wrapper .hide-extraText-wrapper{
    display: flex !important;
  }
  .hide-sample-text .sample-text{ 
    display: none !important;
  }
  .introjs-wide-modal {
    min-width: 400px !important;
    max-width: 450px !important;
  }
  .introjs-button {
    cursor: pointer;
    outline: none;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 ${space.s}  !important;
    min-width: ${space.xl}; 
    user-select: none;
    transition: background-color 0.12s ease-in-out,border-color 0.12s ease-in-out,color 0.15s ease-in-out;
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes.m};
    height: 34px !important;
    display: flex !important;
    text-decoration: none;
    border-radius: ${radius.l} !important;
    text-shadow: none !important;
    box-shadow: none !important;    
  }
  .introjs-nextbutton, .btn-primary {
    border: 1px solid ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL} !important;
    background: linear-gradient(90deg, #0b60da 0%, #2482fd 100%);
    color: ${colors.white} !important;
  }
  .introjs-nextbutton:hover, .btn-primary:hover {
    background: ${colors.white} !important;
    border: 1px solid ${colors.primary} !important;
    color: ${colors.primary} !important;
  }
  .introjs-prevbutton {
    background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL} !important;
    color: ${COLOR_CONSTANTS.SALUTE} !important;
    border: 1px solid ${COLOR_CONSTANTS.SOLITUDE} !important;
  }
  .introjs-prevbutton:hover {
    background-color: ${COLOR_CONSTANTS.WHITE} !important;
  }
  .introjs-disabled {
    display: none !important;
  }
  .introjs-entity-selector-tooltip {
    top: 22px;
  }
  .introjs-bullets ul li a.active {
    background: ${colors.primary} !important;
  }
  .introjs-tooltiptext {
    font-size: ${fontSizes.m} !important;
  }
  .introjs-bullets ul li a, .introjs-bullets ul li a:hover, .introjs-bullets ul li a:focus {
    background: ${COLOR_CONSTANTS.GLACIER_PEARL} !important;   
  }
  .introjs-tooltiptext a {
    color: ${colors.primaryText};
  }
  .scrollbars-hidden-x > div{
    overflow-x: hidden !important;
  }
  .progress-wrapper-show {
    display: flex !important;
  }
  .cliploader-hiding {
    animation: leaves 5s ease-in-out infinite alternate;
  }
  @keyframes leaves {
    0% {
        opacity: 0;
    }
    
    85% {
      opacity: 0;
    }
    
    100% {
        opacity: 1;
    }
  }
  .cover-hide {
    display: none !important;
  }
  .cover-show {
    display: flex !important;
  }
  .image-error {
    border: 3px solid ${colors.error}; 
  }
  .react-player__preview {
    border-radius: inherit; 
  }
  .audio-player {
    width: 100% !important;
  }
  .crello-overlayFrame, .crello-iframe-in-popup, .crello-closeBtn {
    z-index: 2147483001; 
  }
  .swipeable-drawer-mobile .MuiDrawer-paper{
    border-radius: ${space.m} ${space.m} 0 0;
  }
  #credential_picker_iframe {
    outline: none;
  }
  #credential_picker_container {
    outline: none;
    top: 65px !important;
  }
  .shadyside-close svg { 
    color: #404A52;
  }
  .picker-dialog {
    z-index: 2147483002 !important;
    border-radius: ${radius.l};
    border: none;
  }
  .calendly-inline-widget {
    min-width: 100% !important;
    height: 570px !important;
    background: #ffffff;
    border-radius: ${radius.l};
  }
  .calendly-inline-widget iframe, .calendly-badge-widget iframe, .calendly-overlay iframe {
    border-radius: ${radius.l};
  }
`

export const breakpoints = ['900px']
// enable more optional breakpoints, but leave the current system intact
breakpoints.mobile = '0'
breakpoints.mobileLarge = '500px'
breakpoints.tablet = '768px'
breakpoints.desktop = '1024px'
breakpoints.desktopWide = '1366px'
breakpoints.desktopLarge = '1600px'

// This is needed for styled-system to work
export const theme = {
  colors,
  space,
  fontSizes,
  fontStyles,
  fontWeights,
  breakpoints,
}
