import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

const withMeta = (metaProps) => (Component) => {
  const WithMetaComponent = ({ ...props }) => {
    let defaultMeta = {
      name: 'Vista Social',
      title: 'Powerful social media management tools for pros',
      description:
        'Vista Social is a modern take of social media management platforms that you might be using today. Build in 2021 it is slick, has all the features you need and  and extremely affordable.',
      path: '/',
      favicon: '/assets/favicon.ico?v=1',
    }
    defaultMeta = { ...defaultMeta, ...metaProps, ...props.metaDynamic }

    const canonical = `${process.env.NEXT_PUBLIC_SITE_URL}${defaultMeta.path}`
    return (
      <React.Fragment>
        <Head>
          <title>{defaultMeta.title}</title>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="description" content={defaultMeta.description} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1.0" />
          <meta name="title" content={defaultMeta.title} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={defaultMeta.title} />
          <meta property="og:description" content={defaultMeta.description} />
          <meta property="og:image" content={defaultMeta.image} />
          <meta property="og:url" content={canonical} />
          <meta property="fb:app_id" content={defaultMeta.fbapp} />
          <meta property="og:site_name" content={defaultMeta.name} />
          <meta property="og:image:alt" content={defaultMeta.title} />
          <link rel="image_src" href={defaultMeta.image} />
          <link rel="shortcut icon" type="image/x-icon" href={defaultMeta.favicon} />
          <link rel="canonical" href={canonical} />
        </Head>
        <Component {...props} />
      </React.Fragment>
    )
  }

  WithMetaComponent.defaultProps = {
    title: '',
    metaDynamic: {},
  }

  WithMetaComponent.propTypes = {
    title: PropTypes.string,
    metaDynamic: PropTypes.object,
  }

  WithMetaComponent.getInitialProps = (context) => {
    if (Component.getInitialProps) {
      return Component.getInitialProps(context)
    } else return {}
  }
  return WithMetaComponent
}

export default withMeta
