import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, radius, colors } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'

const StyledFlex = styled(Flex)`
  &&& {
    justify-content: space-between;
    align-items: center;
    padding: ${space.m};
    display: flex;
    flex: 1;
    max-width: 100%;
    z-index: 2147483010;
    top: 10px;
    right: 10px;
    background: #ffffff;
    border: 1px solid ${({ borderColor }) => borderColor};
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(102, 130, 165, 0.15);
    border-radius: ${radius.xl};

    > p {
      margin: 0;
    }

    a {
      color: ${colors.primary};
    }
  }
`

const StyledBox = styled(Box)`
  &&& {
    cursor: pointer;
    margin-right: -${space.s};
  }
`

const STATUS_OBJECT = {
  success: { color: '#84DE9D', icon: '/assets/vistasocial/alert_success.svg' },
  info: { color: '#A9CEFF', icon: '/assets/vistasocial/alert_info.svg' },
  error: { color: '#FFC1B4', icon: '/assets/vistasocial/alert_error.svg' },
  warning: { color: '#E69C59', icon: '/assets/vistasocial/alert_error.svg' },
}

const AlertTemplate = ({ id, message, classNames, handleClose, styles, condition }) => {
  const updatedMessage = typeof message === 'string' ? message : JSON.stringify(message)
  const { color, icon } = STATUS_OBJECT[condition]
  return (
    <StyledFlex className={classNames} id={id} style={styles} borderColor={color}>
      <Flex px="s" alignItems="center">
        <Image src={icon} width="22px" height="22px" mr="s" />
        <Text
          px="m"
          color="primaryText"
          fontSize="m"
          dangerouslySetInnerHTML={{
            __html: updatedMessage,
          }}
        />

        <StyledBox onClick={handleClose}>
          <Image src="/assets/vistasocial/alert_close.svg" width="24px" height="24px" />
        </StyledBox>
      </Flex>
    </StyledFlex>
  )
}

AlertTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.any.isRequired,
  classNames: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  condition: PropTypes.string.isRequired,
}

export default AlertTemplate
